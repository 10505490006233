
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import useVuelidate from "@vuelidate/core";
import { helpers,required } from "@vuelidate/validators";
import {
  addProvider,
  deleteProvider,
  getProviders,
  getProvidersList,
  updateProvider,
} from "@/api/provider.api";

export interface Data {
  items: [];
  selectedItem?: { id: string; effectiveDate: Date; name: string };
  createNewMode: boolean;
}
export default defineComponent({
  name: "ProviderCRUDPage",
  components: {},
  setup() {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      items: [],
      selectedItem: undefined,
      createNewMode: true,
    });

    data.selectedItem = {
      id: "",
      name: "",
      effectiveDate: new Date(),
    };

    const providerRules = {
      selectedItem: {
        name: { required:helpers.withMessage("Required", required) },
        address: { required:helpers.withMessage("Required", required) },
        address2: { required:helpers.withMessage("Required", required) },
        city: { required:helpers.withMessage("Required", required) },
        state: { required:helpers.withMessage("Required", required) },
        zip: { required:helpers.withMessage("Required", required) },
        country: { required:helpers.withMessage("Required", required) },
        npi: { required:helpers.withMessage("Required", required) },
        taxId: { required:helpers.withMessage("Required", required) },
      },
    };

    let v$ = useVuelidate(providerRules, data as never);

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Provides");
      await getAll();
    });

    async function switchMode() {
      data.createNewMode = true;
      data.selectedItem = {
        id: "",
        name: "",
        effectiveDate: new Date(),
      };
    }

    async function getAll() {
      data.items = await getProvidersList();
    }

    async function addItem() {
      const result = await v$.value.$validate();
      if (result) {
        await addProvider(data.selectedItem);
        await getAll();
        data.createNewMode = false;
      }
    }

    function selectItem(item) {
      data.selectedItem = item;
      data.createNewMode = false;
      //v$ = useVuelidate(providerRules, data.selectedItem as never);
    }

    async function updateItem() {
      const result = await v$.value.$validate();
      if (result) {
        await updateProvider(data.selectedItem);
      }
    }

    async function deleteItem() {
      await deleteProvider(data.selectedItem);
      await getAll();
    }

    return {
      organizationId,
      data,
      addItem,
      selectItem,
      updateItem,
      deleteItem,
      switchMode,
      v$,
    };
  },
});
